<template>
  <div class="learn text-center">
    <h2>Budgeting is a skill</h2>
    <h4>We all have much to learn</h4>
    <br><br><v-divider /><br><br>
    <h4>Our Blog is on the way!</h4>
  </div>
</template>

<script>
export default {
  name: 'Learn',
  metaInfo: {
    title: 'Learn',
  },
}
</script>
